window.onload = function() {
     checkCookieLaw();
};

function checkCookieLaw(){
   if (window.localStorage["cookieLawKeyMiWeb"] == "true" ){
           $('#avisoCookies').hide();
   }
  var cookies = localStorage.getItem("cookieLawKeyMiWeb");
}

$('#acceptarCookies').click(function(e){
      localStorage.setItem("cookieLawKeyMiWeb", "true");

$('#avisoCookies').hide();

var cookie = localStorage.getItem("cookieLawKeyMiWeb");

});